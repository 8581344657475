import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';

import {BreadcrumbsNav, ButtonIcon, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {stateFormApi} from '../api';
import * as messages from '../messages';
import {Form4022LUStateStatusBadge, Form4022StateStatusBadge, StateStatusBadge} from '../components/badge';
import {formatDate} from '../utils';
import {CountyFormDto, LocalUnitFormDto} from '../types';
import {FormStatus, FormType} from '../enum';
import {openFileInNewWindow} from '../api/apiUtils';

type Props = {
  isLocalUnit4022: boolean
}

const StateLocalUnitFormList = ({
                                  isLocalUnit4022 = false
                                }: Props) => {
  const {countyId, countyFormId, year} = useParams() as {countyId: string, countyFormId: string, year: string};
  const navigate = useNavigate();
  const {showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [countyForm, setCountyForm] = useState<CountyFormDto | null>(null);
  const displayCountyPdfButton = useMemo(() =>
      countyForm && (countyForm.formType === 'FORM_4018_P_R' || countyForm.formType === 'FORM_4023'),
    [countyForm]);
  const countyPdfButtonText = useMemo(() =>
    countyForm && countyForm.formType === 'FORM_4018_P_R' ? 'View Totals PDF' : 'View Countywide PDF', [countyForm]);
  // Remove 'Ad Valorem' or 'Special Acts' from the form name used in the card header as it
  // is also in the form's description
  const scrubbedFormName = useMemo(() => countyForm?.name?.replace('Ad Valorem', '')?.replace('Special Acts', ''), [countyForm]);

  const breadcrumbs = useMemo(() => ([{
    text: 'State Dashboard',
    icon: 'home' as const,
    route: `/state-portal/forms/${year}`
  }, {
    text: countyForm?.countyDisplayName ?? 'County',
    active: true
  }]), [countyForm?.countyDisplayName, year]);

  useEffect(() => {
    const findForm = async () => {
      try {
        const countyForm = await stateFormApi.findCountyForm(countyId, countyFormId);
        setCountyForm(countyForm);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        setLoadingState({loading: false, loadError: true, processing: false});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void findForm();
  }, [countyId, countyFormId, showErrorAlert]);

  const handleOpenPdf = openFileInNewWindow(showErrorAlert, messages.VIEW_PDF_FAILURE);

  const handleViewFormClick = useCallback((localUnitForm: LocalUnitFormDto) => {
    const path = isLocalUnit4022 ?
      `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/${localUnitForm.localUnitId}/forms-local-unit/${localUnitForm.id}` :
      `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/${localUnitForm.localUnitId}/forms/${localUnitForm.id}`;

    navigate(path);
  }, [countyFormId, countyId, navigate, year, isLocalUnit4022]);

  const renderBadge = useMemo(() => (localUnitForm: LocalUnitFormDto) => {
    if (isLocalUnit4022) {
      return <Form4022LUStateStatusBadge status={localUnitForm.status}/>;
    } else if (countyForm?.formType === FormType.FORM_4022_AV || countyForm?.formType === FormType.FORM_4022_SA) {
      return <Form4022StateStatusBadge status={localUnitForm.stateStatus}
                                       is4022Sa={countyForm?.formType === FormType.FORM_4022_SA}/>;
    } else {
      return <StateStatusBadge status={localUnitForm.stateStatus}/>;
    }
  }, [countyForm?.formType, isLocalUnit4022]);

  const renderRow = useMemo(() => (localUnitForm: LocalUnitFormDto) => {
    const {id, stateStatus, localUnitId, localUnitDisplayName} = localUnitForm;
    return (
      <tr key={id}>
        <td className="align-middle text-center" key={id}>
          {renderBadge(localUnitForm)}
        </td>
        <td className="align-middle text-center" key={localUnitId}>{localUnitId}</td>
        <td className="align-middle text-left" key={localUnitDisplayName}>{localUnitDisplayName}</td>
        <td className="align-middle text-center">
          {stateStatus !== FormStatus.NOT_STARTED &&
            <ButtonIcon className="text-warning"
                        ariaLabel="View Form"
                        title="View Form"
                        icon="edit"
                        onClick={() => handleViewFormClick(localUnitForm)}
            />}
        </td>
      </tr>
    );
  }, [handleViewFormClick, renderBadge]);

  const tableProps = useMemo(() => ({
    className: 'mb-0',
    headers: [{
      title: 'Status',
      sortKey: 'status',
      className: 'text-center text-primary'
    }, {
      title: 'Code',
      sortKey: 'localUnitId',
      className: 'text-center text-primary'
    }, {
      title: 'Local Unit',
      sortKey: 'localUnitDisplayName',
      className: 'text-left text-primary'
    }, {
      title: 'View Form',
      className: 'text-center text-primary'
    }],
    items: countyForm?.localUnitForms ?? [],
    initialSort: {
      sortKey: 'localUnitId',
      direction: 'asc' as const
    },
    renderRow
  }), [countyForm?.localUnitForms, renderRow]);

  return <Container fluid className="LocalUnit">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && countyForm &&
      <>
        <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
        {displayCountyPdfButton && <Row className="mb-3">
          <Col className="justify-content-end d-flex">
            <Button color="primary"
                    disabled={loadingState.processing || countyForm.formPdfs.length === 0}
                    onClick={() => handleOpenPdf(countyForm.formPdfs?.[0].url)}>
              {countyPdfButtonText}
            </Button>
          </Col>
        </Row>}
        <Card>
          <CardHeader className="bg-secondary text-white">
            <Row className="d-flex justify-content-between">
              <Col sm="12" md="7">
                Form {scrubbedFormName} {countyForm.description}
              </Col>
              <Col sm="12" md="5" className="d-flex justify-content-md-end">
                Due: {formatDate(countyForm.dueOn)}
              </Col>
            </Row>
          </CardHeader>
          <CustomTable {...tableProps}/>
        </Card>
      </>
    }
  </Container>;
};

export default memo(StateLocalUnitFormList);