import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, CustomInput, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {BreadcrumbsNav, ConfirmationModal, ProgressIndicator, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import {countyFormApi, formImportApi, stateFormApi} from '../api';
import * as messages from '../messages';
import {JsonImportModal} from '../components/shared';
import {Form4024, Form4027i, Form4046} from './forms';
import {FormStatus} from '../enum';
import {AmendmentRequest, CountyFormDto, forms, JsonImportUploadRequest, ReturnRequest} from '../types';

const CountyForm = () => {
  const {countyId, countyFormId, year} = useParams() as {countyId: string, countyFormId: string, year: string};
  const {permissions} = useUserContext();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, processing: false, loadError: false});
  const [countyForm, setCountyForm] = useState<CountyFormDto | undefined>(undefined);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [renderCount, setRenderCount] = useState(1);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [lockModalIsOpen, setLockModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isStatePortal = useMemo(() => {
    return permissions.isStateUser && location.pathname.includes('state-portal');
  }, [location.pathname, permissions.isStateUser]);

  const breadcrumbs = useMemo(() => {
    if (!countyForm) {
      return [];
    } else {
      return [{
        text: isStatePortal ? 'State Dashboard' : 'County Dashboard',
        icon: 'home' as const,
        route: isStatePortal ? `/state-portal/forms/${year}` : `/county-portal/${countyId}/${year}`
      }, {
        text: countyForm!.countyDisplayName!,
        active: true
      }];
    }
  }, [countyForm, countyId, isStatePortal, year]);

  useEffect(() => {
    const loadCountyForm = async () => {
      try {
        const form = isStatePortal ?
          await stateFormApi.findCountyForm(countyId, countyFormId)
          : await countyFormApi.find(countyId, countyFormId);

        setCountyForm(form);
        setLoadingState({loading: false, processing: false, loadError: false});
      } catch (e) {
        setLoadingState({loading: false, processing: false, loadError: true});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void loadCountyForm();
  }, [isStatePortal, countyId, countyFormId, showErrorAlert]);

  const submitButtonText = useMemo(() => {
    if (!countyForm) {
      return '';
    }
    const {
      isOverdue,
      formSubmissions,
      formReturns,
      status
    } = countyForm;
    const hasBeenSubmitted = formSubmissions.length >= 1;
    const formHasBeenReviewed = status === FormStatus.ACCEPTED || formReturns.length >= 1;
    if (hasBeenSubmitted && (isOverdue || formHasBeenReviewed)) {
      return 'Amend';
    } else if (hasBeenSubmitted && !isOverdue) {
      return 'Resubmit';
    } else {
      return 'Submit';
    }
  }, [countyForm]);

  const toggleResetModal = useCallback(() => setResetModalOpen(!resetModalOpen), [resetModalOpen]);

  const handleSetUnsavedChanges = useCallback((unsavedChanges = false) => {
    if (hasUnsavedChanges !== unsavedChanges) {
      setHasUnsavedChanges(unsavedChanges);
    }
  }, [hasUnsavedChanges]);

  const handleResetForm = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      const countyForm = await countyFormApi.reset(countyId, countyFormId);
      setRenderCount(renderCount + 1);
      setCountyForm(countyForm);
      setResetModalOpen(!resetModalOpen);
      showSuccessAlert(messages.FORM_RESET_SUCCESSFUL);
    } catch {
      showErrorAlert(messages.FORM_RESET_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyFormId, countyId, renderCount, loadingState, showErrorAlert, resetModalOpen, showSuccessAlert]);

  const handleSubmit = useCallback(async (countyFormData: forms.CountyFormData) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await countyFormApi.save(countyId, countyFormId, countyFormData);

      const countyForm = await countyFormApi.submit(countyId, countyFormId);
      setCountyForm(countyForm);
      showSuccessAlert(messages.FORM_SUBMIT_SUCCESSFUL);
      navigate(String(breadcrumbs[0].route));
    } catch {
      showErrorAlert(messages.FORM_SUBMIT_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyFormId,
    countyId,
    loadingState,
    showErrorAlert,
    breadcrumbs,
    showSuccessAlert,
    navigate
  ]);

  const handleResubmit = useCallback(async (countyFormData: forms.CountyFormData) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await countyFormApi.save(countyId, countyFormId, countyFormData);

      const form = await countyFormApi.submit(countyId, countyFormId);
      setCountyForm(form);
      showSuccessAlert(messages.FORM_RESUBMIT_SUCCESSFUL);
      navigate(String(breadcrumbs[0].route));
    } catch {
      showErrorAlert(messages.FORM_RESUBMIT_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyFormId,
    countyId,
    loadingState,
    showErrorAlert,
    breadcrumbs,
    showSuccessAlert,
    navigate
  ]);

  const handleSave = useCallback(async (countyFormData: forms.CountyFormData) => {
    setLoadingState({...loadingState, processing: true});

    try {
      const countyForm = await countyFormApi.save(countyId, countyFormId, countyFormData);
      setCountyForm(countyForm);
      showSuccessAlert(messages.FORM_SAVE_SUCCESSFUL);
    } catch {
      showErrorAlert(messages.FORM_SAVE_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyFormId, countyId, loadingState, showErrorAlert, showSuccessAlert]);

  const handleAmend = useCallback(async (amendmentRequest: AmendmentRequest,
                                         countyFormData: forms.CountyFormData) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await countyFormApi.save(countyId, countyFormId, countyFormData);
      const countyForm = await countyFormApi.amend(countyId, countyFormId, amendmentRequest);
      setCountyForm(countyForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(messages.FORM_AMEND_SUCCESSFUL);
      navigate(String(breadcrumbs[0].route));
    } catch (error) {
      showErrorAlert(messages.FORM_AMEND_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyFormId,
    countyId,
    renderCount,
    loadingState,
    showErrorAlert,
    breadcrumbs,
    navigate,
    showSuccessAlert
  ]);

  const handleReturn = useCallback(async (returnRequest: ReturnRequest) => {
    setLoadingState({...loadingState, processing: true});
    try {
      const countyForm = await stateFormApi.returnCountyForm(countyId, countyFormId, returnRequest);
      setCountyForm(countyForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(messages.FORM_RETURN_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.FORM_RETURN_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyFormId, countyId, renderCount, loadingState, showErrorAlert, showSuccessAlert]);

  const handleAccept = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      const countyForm = await stateFormApi.acceptCountyForm(countyId, countyFormId);
      setCountyForm(countyForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(messages.FORM_ACCEPT_SUCCESSFUL);
    } catch {
      showErrorAlert(messages.FORM_ACCEPT_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyFormId, countyId, renderCount, loadingState, showErrorAlert, showSuccessAlert]);

  const handleImport = useCallback(async (jsonUploadRequest: JsonImportUploadRequest,
                                          actions: FormikHelpers<any>) => {
    setLoadingState({...loadingState, processing: true});

    try {
      const formData = new FormData();
      formData.append('jsonFile', jsonUploadRequest.jsonFile as File);
      const {errorMap, uploadedData} = await formImportApi.importCountyForm(countyId, countyFormId, formData);
      if (Object.keys(errorMap).length === 0) {
        setCountyForm({
          ...countyForm,
          data: {...uploadedData}
        } as CountyFormDto);
        showSuccessAlert(messages.IMPORT_SUCCESSFUL);
        setImportModalIsOpen(false);
        setRenderCount(renderCount + 1);
        actions.resetForm();
      } else {
        actions.setFieldError('jsonFile', messages.JSON_IMPORT_INVALID);
        actions.setSubmitting(false);
      }
    } catch (e) {
      showErrorAlert(messages.IMPORT_FAILURE, true);
      setImportModalIsOpen(false);
      actions.setSubmitting(false);
      actions.resetForm();
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyForm, countyFormId, countyId, renderCount, loadingState, showErrorAlert, showSuccessAlert]);

  const handleLockSave = useCallback(async (locked: boolean) => {
    setLoadingState({...loadingState, processing: true});
    setLockModalIsOpen(false);
    try {
      const countyForm = await stateFormApi.updateCountyFormLock(countyId, countyFormId, locked);
      setCountyForm(countyForm);
      setRenderCount(renderCount + 1);
      showSuccessAlert(locked ? messages.FORM_LOCK_SUCCESSFUL : messages.FORM_UNLOCK_SUCCESSFUL);
    } catch {
      showErrorAlert(locked ? messages.FORM_LOCK_FAILURE : messages.FORM_UNLOCK_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyFormId, countyId, renderCount, loadingState, showErrorAlert, setLockModalIsOpen, showSuccessAlert]);

  const resetButtonEnabled = useMemo(() => {
    return !isStatePortal && !['FORM_4027I_AUG', 'FORM_4027I_OCT'].includes(countyForm?.formType ?? '');
  }, [countyForm?.formType, isStatePortal]);
  const displayImportButton = useMemo(() => {
    return !isStatePortal && countyForm?.formType === 'FORM_4046';
  }, [countyForm?.formType, isStatePortal]);
  const importWillOverwriteData = useMemo(() => {
    return countyForm ? countyForm.status !== FormStatus.NOT_STARTED : false;
  }, [countyForm]);

  const displayLock = useMemo(() => {
    return !isStatePortal && countyForm && countyForm.locked;
  }, [countyForm, isStatePortal]);

  const displayLockSwitch = useMemo(() => {
    return isStatePortal && permissions.hasCountyLockAccess && countyForm;
  }, [
    isStatePortal,
    countyForm,
    permissions.hasCountyLockAccess
  ]);

  const handleViewPdfsButtonClick = useCallback(() => {
    const portalUrl = isStatePortal ? 'state-portal' : 'county-portal';
    return navigate(`/${portalUrl}/${countyId}/${year}/forms/${countyFormId}/pdfs`);
  }, [
    year,
    countyId,
    countyFormId,
    navigate,
    isStatePortal
  ]);

  const renderFormComponent = useMemo(() => () => {
    const key = `${countyFormId}-${renderCount}`;
    if (countyForm) {
      switch (countyForm.formType) {
        case 'FORM_4027I_JUNE':
        case 'FORM_4027I_AUG':
        case 'FORM_4027I_OCT':
          return (
            <Form4027i key={key}
                       loading={loadingState.processing}
                       form={countyForm}
                       submitButtonText={submitButtonText}
                       setHasUnsavedChanges={handleSetUnsavedChanges}
                       onSave={handleSave}
                       onSubmit={handleSubmit}
                       onResubmit={handleResubmit}
                       onAmend={handleAmend}
                       onReturn={handleReturn}
                       onAccept={handleAccept}
                       isStateUser={isStatePortal}
            />
          );
        case 'FORM_4024':
          return <Form4024 key={key}
                           form={countyForm}
                           loading={loadingState.processing}
                           onSave={handleSave}
                           setHasUnsavedChanges={handleSetUnsavedChanges}
                           onSubmit={handleSubmit}
                           submitButtonText={submitButtonText}
                           onResubmit={handleResubmit}
                           onAmend={handleAmend}
                           onReturn={handleReturn}
                           onAccept={handleAccept}
                           isStateUser={isStatePortal}
          />;
        case 'FORM_4046':
          return <Form4046 key={key}
                           form={countyForm}
                           loading={loadingState.processing}
                           onSave={handleSave}
                           setHasUnsavedChanges={handleSetUnsavedChanges}
                           submitButtonText={submitButtonText}
                           onSubmit={handleSubmit}
                           onResubmit={handleResubmit}
                           onAmend={handleAmend}
                           onReturn={handleReturn}
                           onAccept={handleAccept}
                           isStateUser={isStatePortal}
          />;
        default:
          return null;
      }
    } else {
      return null;
    }
  }, [
    countyForm,
    countyFormId,
    submitButtonText,
    handleSubmit,
    handleSave,
    handleResubmit,
    handleAmend,
    handleReturn,
    handleAccept,
    isStatePortal,
    loadingState.processing,
    handleSetUnsavedChanges,
    renderCount
  ]);

  return (
    <Container fluid className="countyForm">
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && !loadingState.loadError && <>
        {(displayLock || displayLockSwitch) && <BreadcrumbsNav breadcrumbs={breadcrumbs}/>}
        <Row className="mb-3">
          {(!displayLock && !displayLockSwitch) && <Col className="col-4">
            <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
          </Col>}
          {(displayLock || displayLockSwitch) && <>
            <Col className="pt-2 d-flex col-2 align-items-center">
              {displayLockSwitch && <CustomInput type="switch"
                                                 id="lockFormSwitch"
                                                 name="lockForm"
                                                 label="Locked"
                                                 onChange={() => setLockModalIsOpen(true)}
                                                 checked={countyForm?.locked}
                                                 disabled={loadingState.loading || loadingState.processing}/>}
              {displayLock && <>
                <FontAwesomeIcon className="text-danger ml-2 mr-2"
                                 icon="lock"
                                 title="Locked"
                                 aria-label="Locked"/>
                <strong>Locked</strong>
              </>}
            </Col>
          </>}
          <Col md={!displayLock && !displayLockSwitch ? 8 : 10} className="d-flex justify-content-end">
            {displayImportButton && <Button color="primary"
                                            disabled={loadingState.processing || countyForm?.locked}
                                            onClick={() => {
                                              setImportModalIsOpen(true);
                                            }}>
              County Import
            </Button>}
            <Button color="primary"
                    className="ml-2"
                    disabled={loadingState.loading || (countyForm?.formPdfs?.length ?? 0) === 0}
                    onClick={handleViewPdfsButtonClick}>
              View PDFs
            </Button>
            {resetButtonEnabled && <Button onClick={() => toggleResetModal()}
                                           className="ml-2"
                                           disabled={loadingState.processing || (countyForm?.status !== FormStatus.IN_PROGRESS) || countyForm?.locked}
                                           color="danger">
              Reset Form
            </Button>}
          </Col>
        </Row>
        {renderFormComponent()}

        {countyForm && <ConfirmationModal isOpen={resetModalOpen}
                                          size="lg"
                                          title="Reset Form"
                                          confirmButtonText="Yes"
                                          cancelButtonText="Cancel"
                                          confirmCallback={() => handleResetForm()}
                                          cancelCallback={() => toggleResetModal()}>
          <p>
            Are you sure you want to clear all the data from Form {countyForm.name} {countyForm.description} for <span className="text-danger">{countyForm.countyDisplayName}</span>?
          </p>
        </ConfirmationModal>}
        <JsonImportModal isOpen={importModalIsOpen}
                         onSubmit={handleImport}
                         importWillOverwriteData={importWillOverwriteData}
                         onToggle={() => setImportModalIsOpen(false)}
                         isCountyImport={true}
                         errorMessages={[]}
                         form={countyForm as CountyFormDto}/>

        {countyForm && <ConfirmationModal isOpen={lockModalIsOpen}
                                          size="lg"
                                          title={countyForm.locked ? 'Confirm Unlocking Form' : 'Confirm Locking Form'}
                                          confirmButtonText="Yes"
                                          cancelButtonText="No"
                                          confirmCallback={() => handleLockSave(!countyForm.locked)}
                                          cancelCallback={() => setLockModalIsOpen(false)}>
          <p>
            Are you sure you want to {countyForm.locked ? 'unlock' : 'lock'} Form {countyForm.name} {countyForm.description} for <span className="text-danger">{countyForm.countyDisplayName}</span>?
          </p>
        </ConfirmationModal>}
      </>
      }
    </Container>
  );
};

export default CountyForm;