import {useEffect, useMemo, useState} from 'react';
import {Card, CardHeader, Container} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {BreadcrumbsNav, ButtonIcon, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {filingCabinetApi, megApi} from '../api';
import * as messages from '../messages';
import {formatDate} from '../utils';
import {FileUpload, LocalUnit} from '../types';
import {downloadFile} from '../api/apiUtils';

const LocalUnitFilingCabinet = () => {
  const {localUnitId, year} = useParams() as {localUnitId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [localUnit, setLocalUnit] = useState<LocalUnit | undefined>(undefined);
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);
  const {showErrorAlert} = useAlerts();

  useEffect(() => {
    const findFileUploads = async () => {
      try {
        const [fileUploads, localUnits] = await Promise.all([
          filingCabinetApi.findFilesByLocalUnitIdAndYear(localUnitId, year),
          megApi.findLocalUnits()
        ]);
        setFileUploads(fileUploads);
        const currentLocalUnit = localUnits.filter(localUnit => localUnit.id === Number(localUnitId))[0];
        setLocalUnit(currentLocalUnit);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }

    };

    void findFileUploads();
  }, [localUnitId, year, showErrorAlert]);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Local Unit Dashboard',
      active: false,
      route: `/local-unit-portal/${localUnitId}/${year}`,
      icon: 'home' as const
    },
    {text: localUnit?.displayNameWithType ?? '', active: true}
  ]), [localUnitId, year, localUnit]);

  const handleDownload = downloadFile(showErrorAlert);

  const tableProps = useMemo(() => ({
    headers: [
      {
        title: 'Date',
        className: 'text-nowrap text-center',
        sortKey: 'createdAt'
      },
      {
        title: 'File Name',
        className: 'text-nowrap',
        sortKey: 'fileName'
      },
      {
        title: 'File Description',
        className: 'text-nowrap'
      }, {
        title: 'View File',
        className: 'text-nowrap text-center'
      }
    ],
    noResultsMessage: messages.NO_FILES_UPLOADED,
    items: fileUploads,
    renderRow: (fileUpload: FileUpload) => <tr key={fileUpload.id}>
      <td className="text-center align-middle">
        {formatDate(fileUpload.createdAt)}
      </td>
      <td className="align-middle">
        {fileUpload.fileName}
      </td>
      <td className="align-middle">
        {fileUpload.description}
      </td>
      <td className="text-center align-middle">
        <ButtonIcon ariaLabel="View File"
                    title="View File"
                    size="lg"
                    icon="paperclip"
                    onClick={() => handleDownload(fileUpload.url)}
        />
      </td>
    </tr>,
    initialSort: {
      sortKey: 'createdAt',
      direction: 'desc' as const
    }
  }), [
    handleDownload,
    fileUploads
  ]);

  return <Container fluid className="CountyFilingCabinet">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Card className="mt-2">
        <CardHeader className="bg-secondary text-white">
          Filing Cabinet
        </CardHeader>
        <CustomTable {...tableProps}/>
      </Card>
    </>}
  </Container>;
};

export default LocalUnitFilingCabinet;