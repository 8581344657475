import {memo, useMemo} from 'react';
import {Badge} from 'reactstrap';

import {FormStatus} from '../../enum';

type Props = {
  status: string
  returnedFromStateDisplayText?: string
}

// This represents the County view of the Form 4022 Local Unit form, filled out by the county user
const Form4022CountyStatusBadge = ({
                                     status,
                                     returnedFromStateDisplayText = FormStatus.RETURNED_FROM_STATE
                                   }: Props) => {
  const color = useMemo(() => {
    if (status === FormStatus.ACCEPTED ||
      status === FormStatus.SUBMITTED) {
      return 'success';
    } else if (status === FormStatus.RECEIVED ||
      status === FormStatus.RETURNED_FROM_STATE) {
      return 'danger';
    } else {
      return 'light';
    }
  }, [status]);

  const statusDisplayValue = useMemo(() => {
    if (status === FormStatus.RETURNED_FROM_STATE) {
      return returnedFromStateDisplayText;
    } else if (status === FormStatus.RETURNED_FROM_COUNTY) {
      return 'Returned to Local Unit';
    } else {
      return status.replace(/_/g, ' ');
    }
  }, [status, returnedFromStateDisplayText]);

  return (
    <Badge color={color}
           className="font-size-100 text-uppercase border-0">
      {statusDisplayValue}
    </Badge>
  );
};

export default memo(Form4022CountyStatusBadge);