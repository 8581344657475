import * as Yup from 'yup';

import * as messages from '../messages';

const studyCertificationSchema = Yup.object().shape({
  hasLandValueAnalysisBeenUploaded: Yup.boolean()
    .isTrue(messages.REQUIRED),
  hasEcfAnalysisBeenUploaded: Yup.boolean()
    .isTrue(messages.REQUIRED),
  hasForm3215BeenUploaded: Yup.boolean()
    .isTrue(messages.REQUIRED)
});

export default studyCertificationSchema;