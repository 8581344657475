import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  ProgressIndicator,
  ProgressModal,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {countyFormApi, formImportApi} from '../api';
import * as messages from '../messages';
import {CountyStatusBadge, Form4022CountyStatusBadge, LocalUnitStatusBadge} from '../components/badge';
import {FormStatus, FormType} from '../enum';
import {CountyFormDto, forms, JsonImportUploadRequest, LocalUnitFormDto} from '../types';
import {openFileInNewWindow} from '../api/apiUtils';
import {JsonImportModal} from '../components/shared';
import {formatDate} from '../utils';

const LocalUnitFormList = () => {
  const {countyId, countyFormId, year} = useParams() as {countyId: string, countyFormId: string, year: string};
  const location = useLocation();
  const navigate = useNavigate();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {permissions} = useUserContext();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [countyForm, setCountyForm] = useState<CountyFormDto | undefined>(undefined);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [submitAllModalIsOpen, setSubmitAllModalIsOpen] = useState(false);
  const [isSendAllToReviewModalOpen, setIsSendAllToReviewModalOpen] = useState(false);
  const [progressModalIsOpen, setProgressModalIsOpen] = useState(false);
  const [importHasErrors, setImportHasErrors] = useState(false);
  const [progressModalActionText, setProgressModalActionText] = useState({
    header: 'Submitting All Forms',
    action: 'submitted'
  });

  const importWillOverwriteData = useMemo(() =>
    countyForm?.localUnitForms?.some((localUnitForm: LocalUnitFormDto) =>
      localUnitForm.status !== FormStatus.NOT_STARTED) ?? true, [countyForm]);
  const shouldDisplayImportButton = useMemo(() => countyForm && countyForm.formType !== 'FORM_4023', [countyForm]);
  const shouldDisplayCountyPdfButton = useMemo(() =>
    countyForm && (countyForm.formType === 'FORM_4018_P_R' || countyForm.formType === 'FORM_4023'), [countyForm]);
  const countyPdfButtonText = useMemo(() =>
    countyForm && countyForm.formType === 'FORM_4018_P_R' ? 'View Totals PDF' : 'View Countywide PDF', [countyForm]);
  const displaySubmitAllButton = useMemo(() => countyForm &&
      countyForm.formType !== FormType.FORM_4022_AV &&
      countyForm.formType !== FormType.FORM_4022_SA &&
      countyForm.formType !== FormType.FORM_4022_LU_AV &&
      countyForm.formType !== FormType.FORM_4022_LU_SA &&
      permissions.hasCountySubmitAccess,
    [countyForm, permissions]);
  // Remove 'Ad Valorem' or 'Special Acts' from the form name used in the card header as it
  // is also in the form's description
  const scrubbedFormName = useMemo(() =>
    countyForm?.name?.replace('Ad Valorem', '')?.replace('Special Acts', ''), [countyForm]);
  const isLocalUnitPortal = useMemo(() => location.pathname.includes('local-unit-portal'), [location.pathname]);

  const breadcrumbs = useMemo(() => ([{
    text: isLocalUnitPortal ? 'Local Unit Dashboard' : 'County Dashboard',
    icon: 'home' as const,
    route: `/county-portal/${countyId}/${year}`
  }, {
    text: countyForm?.countyDisplayName ?? 'County',
    active: true
  }]), [countyForm?.countyDisplayName, countyId, isLocalUnitPortal, year]);

  useEffect(() => {
    const loadCountyForm = async () => {
      try {
        const form = await countyFormApi.find(countyId, countyFormId);
        setCountyForm(form);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        setLoadingState({loading: false, loadError: true, processing: false});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void loadCountyForm();
  }, [countyId, countyFormId, showErrorAlert]);

  const handleEditFormClick = useCallback((localUnitForm: LocalUnitFormDto) => {
    navigate(`${location.pathname}/${localUnitForm.localUnitId}/forms/${localUnitForm.id}`);
  }, [navigate, location.pathname]);

  const handleSubmitAll = useCallback(async () => {
    setSubmitAllModalIsOpen(false);
    setProgressModalIsOpen(true);
    setProgressModalActionText({
      header: 'Submitting All Forms',
      action: 'submitted'
    });
    setLoadingState({...loadingState, processing: true});

    try {
      const countyForm = await countyFormApi.submitAll(countyId, countyFormId);
      setCountyForm(countyForm);
      showSuccessAlert(messages.FORMS_SUBMIT_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.FORMS_SUBMIT_FAILURE, true);
    }

    setProgressModalIsOpen(false);
    setLoadingState({...loadingState, processing: false});
  }, [countyFormId, countyId, loadingState, showErrorAlert, showSuccessAlert]);

  const handleSendAllToReview = useCallback(async () => {
    setIsSendAllToReviewModalOpen(false);
    setLoadingState({...loadingState, processing: true});
    setProgressModalIsOpen(true);
    setProgressModalActionText({
      header: 'Sending to Local Units',
      action: 'sent'
    });

    try {
      const countyForm = await countyFormApi.sendAllToReview(countyId, countyFormId);
      setCountyForm(countyForm);
      showSuccessAlert(messages.FORMS_SENT_TO_LOCAL_UNITS_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.FORMS_SENT_TO_LOCAL_UNITS_FAILURE, true);
    }

    setProgressModalIsOpen(false);
    setLoadingState({...loadingState, processing: false});
  }, [countyFormId, countyId, loadingState, showErrorAlert, showSuccessAlert]);

  const handleImport = useCallback(async ({
                                            jsonFile,
                                            mappingKeys
                                          }: JsonImportUploadRequest, {
                                            setSubmitting,
                                            resetForm,
                                            setFieldError
                                          }: FormikHelpers<JsonImportUploadRequest>) => {
    setLoadingState({...loadingState, processing: true});
    try {
      const formData = new FormData();
      formData.append('jsonFile', jsonFile as File);
      mappingKeys.forEach(mappingKey => formData.append('mappingKeys', mappingKey));

      const {errorMap} = await formImportApi.importCountyForm(countyId, countyFormId, formData);

      if (Object.keys(errorMap).length === 0) {
        const form = await countyFormApi.find(countyId, countyFormId);
        setCountyForm(form);
        showSuccessAlert(messages.IMPORT_SUCCESSFUL);
        setImportModalIsOpen(false);
        resetForm();
      } else if (Object.keys(errorMap).length > 0 && countyForm?.formType === 'FORM_4018_P_R') {
        setImportHasErrors(true);
        setSubmitting(false);
      } else {
        setFieldError('jsonFile', messages.JSON_IMPORT_INVALID);
        setSubmitting(false);
      }
    } catch (e) {
      showErrorAlert(messages.IMPORT_FAILURE, true);
      setImportModalIsOpen(false);
      setSubmitting(false);
      resetForm();
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyId, countyForm, countyFormId, loadingState, showErrorAlert, showSuccessAlert]);

  const handleJsonImportMappingKeyToggle = useCallback(() => setImportHasErrors(false), []);

  const renderStatusBadge = useMemo(() => (localUnitForm: LocalUnitFormDto) => {
    const is4022Form = localUnitForm.formType === FormType.FORM_4022_AV || localUnitForm.formType === FormType.FORM_4022_SA;

    if (permissions.isCountyUser && !isLocalUnitPortal && !is4022Form) {
      return <CountyStatusBadge status={localUnitForm.countyStatus}/>;
    } else if (permissions.isCountyUser && !isLocalUnitPortal && is4022Form) {
      return <Form4022CountyStatusBadge status={localUnitForm.countyStatus}/>;
    } else {
      return <LocalUnitStatusBadge status={localUnitForm.status}/>;
    }
  }, [isLocalUnitPortal, permissions.isCountyUser]);

  const handleOpenPdf = openFileInNewWindow(showErrorAlert, messages.VIEW_PDF_FAILURE);

  const renderRow = useMemo(() => (localUnitForm: LocalUnitFormDto) => {
    const {id, localUnitId, localUnitDisplayName} = localUnitForm;
    return (
      <tr key={id}>
        <td className="align-middle text-center" key={id}>
          {renderStatusBadge(localUnitForm)}
        </td>
        <td className="align-middle text-center" key={localUnitId}>
          {localUnitId}
        </td>
        <td className="align-middle text-left" key={localUnitDisplayName}>
          {localUnitDisplayName}
        </td>
        <td className="align-middle text-center">
          <ButtonIcon className="text-warning"
                      ariaLabel="Edit Form"
                      title="Edit Form"
                      icon="edit"
                      onClick={() => handleEditFormClick(localUnitForm)}
          />
        </td>
      </tr>
    );
  }, [handleEditFormClick, renderStatusBadge]);

  const tableProps = useMemo(() => ({
    className: 'mb-0',
    headers: [{
      title: 'Status',
      sortKey: 'status',
      className: 'text-center text-primary'
    }, {
      title: 'Code',
      sortKey: 'localUnitId',
      className: 'text-center text-primary'
    }, {
      title: 'Local Unit',
      sortKey: 'localUnitDisplayName',
      className: 'text-left text-primary'
    }, {
      title: 'View Form',
      className: 'text-center text-primary'
    }],
    items: countyForm?.localUnitForms ?? [],
    renderRow,
    initialSort: {
      sortKey: 'localUnitId',
      direction: 'asc' as const
    }
  }), [countyForm?.localUnitForms, renderRow]);

  const isSubmitAllDisabled = useMemo(() => {
    if (!countyForm) {
      return true;
    }

    if (countyForm.localUnitForms.filter(localUnitForm => localUnitForm.countyStatus === FormStatus.NOT_STARTED).length > 0) {
      return true;
    } else if (countyForm.formType === FormType.FORM_4014A) {
      return countyForm.localUnitForms.filter(localUnitForm => !(localUnitForm.data as forms.Form4014aDto).complete).length > 0;
    } else if (countyForm.formType === FormType.FORM_4015) {
      return countyForm.localUnitForms.filter(localUnitForm => !(localUnitForm.data as forms.Form4015Dto).complete).length > 0;
    } else if (countyForm.formType === FormType.FORM_4015A) {
      return countyForm.localUnitForms.filter(localUnitForm => !(localUnitForm.data as forms.Form4015aDto).complete).length > 0;
    } else {
      return false;
    }
  }, [countyForm]);

  const isSendAllToReviewDisabled = useMemo(() => {
    if (!countyForm || countyForm.formType !== FormType.FORM_4015) {
      return true;
    }

    if (countyForm.localUnitForms.filter(localUnitForm => localUnitForm.countyStatus === FormStatus.NOT_STARTED).length > 0) {
      return true;
    } else if (countyForm.localUnitForms.filter(localUnitForm => localUnitForm.status === FormStatus.IN_PROGRESS).length === 0) {
      return true;
    } else {
      return countyForm.localUnitForms.filter(localUnitForm => !(localUnitForm.data as forms.Form4015Dto).complete).length > 0;
    }
  }, [countyForm]);

  const showSendToLocalUnitsButton = useMemo(() => {
    if (!countyForm || countyForm.formType !== FormType.FORM_4015) {
      return false;
    }

    const localUnitFormCount = countyForm.localUnitForms.length;
    return countyForm.localUnitForms.filter(localUnitForm =>
        localUnitForm.status === FormStatus.NOT_STARTED ||
        localUnitForm.status === FormStatus.IN_PROGRESS).length !== 0 &&
      countyForm.localUnitForms.filter(localUnitForm => !localUnitForm.locked || localUnitForm.hasSubmissions).length === localUnitFormCount;
  }, [countyForm]);

  const isForm4015AndAnyHaveNotBeenSentToReview = useMemo(() => {
    return countyForm &&
      countyForm.formType === 'FORM_4015' &&
      countyForm.localUnitForms.filter(localUnitForm => !localUnitForm.hasBeenSentToReview).length > 0;
  }, [countyForm]);

  const handleImportModalToggle = useCallback(() => {
    setImportHasErrors(false);
    setImportModalIsOpen(false);
  }, []);

  return (
    <Container fluid className="LocalUnit">
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && !loadingState.loadError && countyForm &&
        <>
          <Row className="mb-3">
            <Col md="6">
              <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
            </Col>
            {(shouldDisplayCountyPdfButton || shouldDisplayImportButton) &&
              <Col md="6" className="justify-content-end d-flex">
                {shouldDisplayCountyPdfButton &&
                  <Button color="primary"
                          disabled={loadingState.processing || countyForm.formPdfs.length === 0}
                          onClick={() => handleOpenPdf(countyForm.formPdfs?.[0].url)}>
                    {countyPdfButtonText}
                  </Button>}
                {shouldDisplayImportButton &&
                  <Button color="primary"
                          className="ml-2"
                          disabled={loadingState.processing}
                          onClick={() => setImportModalIsOpen(true)}>
                    County Import
                  </Button>}
              </Col>}
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-secondary text-white">
                  <Row className="d-flex justify-content-between">
                    <Col sm="12" md="7">
                      Form {scrubbedFormName} {countyForm.description}
                    </Col>
                    <Col sm="12" md="5" className="d-flex justify-content-md-end">
                      Due: {formatDate(countyForm.dueOn)}
                    </Col>
                  </Row>
                </CardHeader>
                <CustomTable {...tableProps}/>
              </Card>
            </Col>
          </Row>
          {displaySubmitAllButton && <>
            <Row className="mt-3">
              <Col className="d-flex justify-content-end text-right text-secondary">
                {!showSendToLocalUnitsButton && <strong>All forms must be complete to submit all</strong>}
                {showSendToLocalUnitsButton && <strong>All forms must be complete to send or submit all</strong>}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                {showSendToLocalUnitsButton &&
                  <Button color="primary"
                          className="mr-2"
                          id="sendAllToReviewButton"
                          disabled={isSendAllToReviewDisabled}
                          onClick={() => setIsSendAllToReviewModalOpen(!isSendAllToReviewModalOpen)}>
                    Send All to Local Units
                  </Button>}
                <Button color="primary"
                        id="submitAllButton"
                        disabled={isSubmitAllDisabled}
                        onClick={() => setSubmitAllModalIsOpen(!submitAllModalIsOpen)}>
                  Submit All
                </Button>
              </Col>
            </Row>
          </>}
        </>}
      {countyForm && <>
        <JsonImportModal isOpen={importModalIsOpen}
                         onSubmit={handleImport}
                         hasErrors={importHasErrors}
                         isCountyImport={true}
                         errorMessages={[]}
                         onMappingKeyToggle={handleJsonImportMappingKeyToggle}
                         importWillOverwriteData={importWillOverwriteData}
                         onToggle={handleImportModalToggle}
                         form={countyForm as CountyFormDto}/>
        <ConfirmationModal isOpen={submitAllModalIsOpen}
                           size="lg"
                           title="Submit Forms"
                           aria-modal={true}
                           confirmButtonText="Yes"
                           cancelButtonText="Cancel"
                           confirmCallback={handleSubmitAll}
                           cancelCallback={() => setSubmitAllModalIsOpen(false)}>
          <p>
            {isForm4015AndAnyHaveNotBeenSentToReview && <>
              Are you sure you want to submit the Form {countyForm.name} {countyForm.description} for <span className="text-danger">{countyForm.countyDisplayName}</span> without reconciling in MEG with the local units?
            </>}
            {!isForm4015AndAnyHaveNotBeenSentToReview && <>
              Are you sure you want to submit the Form {countyForm.name} {countyForm.description} for <span className="text-danger">{countyForm.countyDisplayName}</span>?
            </>}
          </p>
        </ConfirmationModal>
        <ConfirmationModal isOpen={isSendAllToReviewModalOpen}
                           size="lg"
                           title="Send to Local Units"
                           aria-modal={true}
                           confirmButtonText="Yes"
                           cancelButtonText="Cancel"
                           confirmCallback={handleSendAllToReview}
                           cancelCallback={() => setIsSendAllToReviewModalOpen(false)}>
          <p>
            Are you sure you want to send the {countyForm.description} Forms for <span className="text-danger">{countyForm.countyDisplayName}</span> to the local units for review?
          </p>
        </ConfirmationModal>
        <ProgressModal isOpen={progressModalIsOpen}
                       title={progressModalActionText.header}
                       content={`Forms are being ${progressModalActionText.action}. Please do not refresh the page, as this could take a few moments.`}/>

      </>}
    </Container>
  );
};

export default memo(LocalUnitFormList);