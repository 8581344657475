import {ConfirmationModal} from '@reasoncorp/kyber-js';

import CommentModal from './CommentModal';
import {Comment, CountyFormDto, LocalUnitFormDto, ReturnRequest} from '../../types';

type Props = {
  form: LocalUnitFormDto | CountyFormDto
  acceptModalIsOpen: boolean
  returnModalIsOpen: boolean
  onReturn: (returnRequest: ReturnRequest) => void
  onAccept: () => void
  onReturnCancel: () => void
  onAcceptCancel: () => void
}

const StateFormModals = ({
                           form,
                           acceptModalIsOpen,
                           returnModalIsOpen,
                           onReturn,
                           onAccept,
                           onReturnCancel,
                           onAcceptCancel
                         }: Props) => {
  return <>
    <CommentModal isOpen={returnModalIsOpen}
                  modalTitle="Reason for Return"
                  onToggle={onReturnCancel}
                  onSubmit={(returnRequest: Comment) => onReturn(returnRequest as ReturnRequest)}
                  confirmButtonText="Return"
                  cancelButtonText="Cancel">
      <p>
        Provide a reason for returning the Form {form.name} {form.description} for <span className="text-danger">{form.localUnitDisplayName || form.countyDisplayName}</span>.
      </p>
    </CommentModal>
    <ConfirmationModal isOpen={acceptModalIsOpen}
                       size="lg"
                       title="Accept Form"
                       confirmButtonText="Yes"
                       cancelButtonText="No"
                       aria-modal={true}
                       confirmCallback={onAccept}
                       cancelCallback={onAcceptCancel}>
      <p>
        Are you sure you want to accept Form {form.name} {form.description} for <span className="text-danger">{form.localUnitDisplayName || form.countyDisplayName}</span>?
      </p>
    </ConfirmationModal>
  </>;
};

export default StateFormModals;