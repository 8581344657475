import {useCallback, useMemo, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import {Button, Card, CardBody, Col, Row, Table} from 'reactstrap';
import {get} from 'lodash';
import {useLocation} from 'react-router-dom';

import {ConfirmationModal, MiSuiteRole, ssoUtils, useUserContext} from '@reasoncorp/kyber-js';

import {
  CommentModal,
  FormHeader,
  FormHistoryTable,
  LocalUnitFormNavigator,
  StateFormButtons
} from '../../components/shared';
import {format4022AdjustmentValueWithCommas, formatInteger} from '../../utils';
import {FormStatus} from '../../enum';
import {form4022Schema} from '../../schemas';
import {AmendmentRequest, FormReturn, forms, LocalUnitFormDto, ReturnRequest} from '../../types';
import {Form4022Table} from '../../components/forms';

const mapProperty = (form4022: forms.Form4022Dto, key: string, classification: string) => {
  return {
    propertyCount: get(form4022, `${key}.${classification}.propertyCount`, null),
    boardOfReview1: get(form4022, `${key}.${classification}.boardOfReview1`, null),
    loss: get(form4022, `${key}.${classification}.loss`, null),
    adjustment: get(form4022, `${key}.${classification}.adjustment`, ''),
    newField: get(form4022, `${key}.${classification}.newField`, null)
  };
};

type Props = {
  form: LocalUnitFormDto
  isStateUser: boolean
  isCountyUser: boolean
  isLocalUnitUser: boolean
  hasSubmitAccess: boolean
  loading: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  onSave: (localUnitFormData: forms.LocalUnitFormData) => void
  onReturn: (returnRequest: ReturnRequest) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.Form4022Dto) => void
  onSubmitToCounty: (localUnitFormData: forms.Form4022Dto) => void
  onSubmitToState: (localUnitFormData: forms.Form4022Dto) => void
  onReturnToLocalUnit: (returnRequest: ReturnRequest) => void
  onResubmit: (localUnitFormData: forms.Form4022Dto) => void
  onAccept: () => void
  isStateViewLocalUnit4022?: boolean
}

const Form4022 = ({
                    form,
                    isStateUser,
                    isCountyUser,
                    isLocalUnitUser,
                    hasSubmitAccess,
                    loading,
                    setHasUnsavedChanges,
                    onSave,
                    onReturn,
                    onAmend,
                    onSubmitToCounty,
                    onSubmitToState,
                    onReturnToLocalUnit,
                    onResubmit,
                    onAccept,
                    isStateViewLocalUnit4022 = false
                  }: Props) => {
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [resubmitModalIsOpen, setResubmitModalIsOpen] = useState(false);
  const [commentModalIsOpen, toggleCommentModal] = useState(false);
  const [returnLocalUnitModalIsOpen, toggleReturnLocalUnitModalIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const {permissions, currentUser} = useUserContext();
  const location = useLocation();
  const isStatePortal = useMemo(() => {
    return permissions.isStateUser && location.pathname.indexOf('state-portal') !== -1;
  }, [
    location.pathname,
    permissions.isStateUser
  ]);

  const isLocalUnitPortal = useMemo(() => {
    return permissions.isLocalUnitUser && location.pathname.indexOf('local-unit-portal') !== -1;
  }, [
    location.pathname,
    permissions.isLocalUnitUser
  ]);

  const form4022Dto = useMemo(() => {
    // Use latest form submission data if available for state users
    if (isStateViewLocalUnit4022 && isStateUser && form.latestLocalUnitSubmissionData !== null) {
      return form.latestLocalUnitSubmissionData as forms.Form4022Dto;
    } else if (!isStateViewLocalUnit4022 && isStateUser && form.latestSubmissionData !== null) {
      return form.latestSubmissionData as forms.Form4022Dto;
    } else if (isLocalUnitPortal && form.localUnitData !== null) {
      return form.localUnitData as forms.Form4022Dto;
    } else {
      return form.data as forms.Form4022Dto;
    }
  }, [
    form,
    isStateViewLocalUnit4022,
    isStateUser,
    isLocalUnitPortal
  ]);

  const hasLocalUnitSubmitAccess = useMemo(() => {
    return ssoUtils.hasJurisdictionCanonicalIdAndRole(currentUser, form.localUnitId, MiSuiteRole.ASSESSING_DEPUTY) ||
      ssoUtils.hasJurisdictionCanonicalIdAndRole(currentUser, form.localUnitId, MiSuiteRole.ASSESSOR_OF_RECORD);
  }, [form.localUnitId, currentUser]);

  const initialValues = useMemo(() => ({
    realProperty: {
      100: mapProperty(form4022Dto, 'realProperty', '100'),
      200: mapProperty(form4022Dto, 'realProperty', '200'),
      300: mapProperty(form4022Dto, 'realProperty', '300'),
      400: mapProperty(form4022Dto, 'realProperty', '400'),
      500: mapProperty(form4022Dto, 'realProperty', '500'),
      600: mapProperty(form4022Dto, 'realProperty', '600')
    },
    personalProperty: {
      150: mapProperty(form4022Dto, 'personalProperty', '150'),
      250: mapProperty(form4022Dto, 'personalProperty', '250'),
      350: mapProperty(form4022Dto, 'personalProperty', '350'),
      450: mapProperty(form4022Dto, 'personalProperty', '450'),
      550: mapProperty(form4022Dto, 'personalProperty', '550')
    }
  }), [
    form4022Dto
  ]);

  const getSaveableData = useCallback((formikProps: FormikProps<any>) => {
    return {
      type: 'FORM_4022',
      ...formikProps.values
    };
  }, []);

  const calculateBoardOfReview2 = useCallback((item: {
                                                       boardOfReview1: number | null,
                                                       loss: number | null,
                                                       adjustment: number | null,
                                                       newField: number | null
                                                     } | null) => {
    if (item === null) {
      return 0;
    } else {
      const {boardOfReview1 = 0, loss = 0, adjustment = 0, newField = 0} = item;
      const adjustmentNumberValue = adjustment && isFinite(Number(adjustment)) ? Number(adjustment) : 0;
      return (isFinite(Number(boardOfReview1)) ? Number(boardOfReview1) : 0) - (isFinite(Number(loss)) ? Number(loss) : 0) + adjustmentNumberValue + (isFinite(Number(newField)) ? Number(newField) : 0);
    }
  }, []);

  const calculateTotals = useCallback((formikProps: FormikProps<any>) => {
    const values = formikProps.values as forms.Form4022Dto;
    const totals = {
      realProperty: {
        propertyCount: Object.values(values.realProperty).map(c => c.propertyCount && isFinite(Number(c.propertyCount)) ? Number(c.propertyCount) : 0).reduce((a, b) => a + b),
        boardOfReview1: Object.values(values.realProperty).map(c => c.boardOfReview1 && isFinite(Number(c.boardOfReview1)) ? Number(c.boardOfReview1) : 0).reduce((a, b) => a + b),
        boardOfReview2: Object.values(values.realProperty).map(c => calculateBoardOfReview2(c)).reduce((a, b) => a + b),
        loss: Object.values(values.realProperty).map(c => c.loss && isFinite(Number(c.loss)) ? Number(c.loss) : 0).reduce((a, b) => a + b),
        adjustment: Object.values(values.realProperty).map(c => c.adjustment && isFinite(Number(c.adjustment)) ? Number(c.adjustment) : 0).reduce((a, b) => a + b),
        newField: Object.values(values.realProperty).map(c => c.newField && isFinite(Number(c.newField)) ? Number(c.newField) : 0).reduce((a, b) => a + b)
      },
      personalProperty: {
        propertyCount: Object.values(values.personalProperty).map(c => c.propertyCount && isFinite(Number(c.propertyCount)) ? Number(c.propertyCount) : 0).reduce((a, b) => a + b),
        boardOfReview1: Object.values(values.personalProperty).map(c => c.boardOfReview1 && isFinite(Number(c.boardOfReview1)) ? Number(c.boardOfReview1) : 0).reduce((a, b) => a + b),
        boardOfReview2: Object.values(values.personalProperty).map(c => calculateBoardOfReview2(c)).reduce((a, b) => a + b),
        loss: Object.values(values.personalProperty).map(c => c.loss && isFinite(Number(c.loss)) ? Number(c.loss) : 0).reduce((a, b) => a + b),
        adjustment: Object.values(values.personalProperty).map(c => c.adjustment && isFinite(Number(c.adjustment)) ? Number(c.adjustment) : 0).reduce((a, b) => a + b),
        newField: Object.values(values.personalProperty).map(c => c.newField && isFinite(Number(c.newField)) ? Number(c.newField) : 0).reduce((a, b) => a + b)
      },
      all: {
        propertyCount: 0,
        boardOfReview1: 0,
        boardOfReview2: 0,
        loss: 0,
        adjustment: 0,
        newField: 0
      }
    };

    totals.all = {
      propertyCount: totals.realProperty.propertyCount + totals.personalProperty.propertyCount,
      boardOfReview1: totals.realProperty.boardOfReview1 + totals.personalProperty.boardOfReview1,
      boardOfReview2: totals.realProperty.boardOfReview2 + totals.personalProperty.boardOfReview2,
      loss: totals.realProperty.loss + totals.personalProperty.loss,
      adjustment: totals.realProperty.adjustment + totals.personalProperty.adjustment,
      newField: totals.realProperty.newField + totals.personalProperty.newField
    };

    return totals;
  }, [
    calculateBoardOfReview2
  ]);

  const toggleResubmitModal = useCallback((confirmResubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmResubmit && formikProps) {
      setHasUnsavedChanges(true);
      onResubmit(getSaveableData(formikProps));
    }

    setResubmitModalIsOpen(!resubmitModalIsOpen);
  }, [
    getSaveableData,
    onResubmit,
    resubmitModalIsOpen,
    setHasUnsavedChanges
  ]);

  const handleSave = useCallback((formikProps: FormikProps<any>) => onSave(getSaveableData(formikProps)), [
    getSaveableData,
    onSave
  ]);

  const toggleSubmitModal = useCallback((confirmSubmit = false,
                                         isLocalUnitUser: boolean,
                                         formikProps?: FormikProps<any>) => {
    if (confirmSubmit && formikProps) {
      setHasUnsavedChanges(true);
      isLocalUnitUser ? onSubmitToCounty(getSaveableData(formikProps)) : onSubmitToState(getSaveableData(formikProps));
    }

    setSubmitModalIsOpen(!submitModalIsOpen);
  }, [
    onSubmitToCounty,
    onSubmitToState,
    getSaveableData,
    setHasUnsavedChanges,
    submitModalIsOpen
  ]);

  const handleAmend = useCallback((formikProps: FormikProps<any>, amendmentRequest: AmendmentRequest) => {
    setHasUnsavedChanges(true);
    onAmend({...amendmentRequest, localUnitUser: isLocalUnitUser}, getSaveableData(formikProps));
  }, [
    getSaveableData,
    isLocalUnitUser,
    onAmend,
    setHasUnsavedChanges
  ]);

  const toggleAcceptModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      onAccept();
    }
    setAcceptModalIsOpen(!acceptModalIsOpen);
  }, [onAccept, acceptModalIsOpen]);

  const renderLocalUnitSubmitButton = useMemo(() => (formikProps: FormikProps<any>) => {
    let disabled = loading || !formikProps.isValid || formikProps.isSubmitting;
    let callback, color, text;
    if (form.hasSubmissions) {
      // form has been submitted at least once but not amended.
      if (form.formReturns.length === 0 || (form.status === FormStatus.SUBMITTED)) {
        disabled = true;
      }

      // form has been submitted to county and returned
      if (form.formSubmissions &&
        form.formSubmissions.length > 0 &&
        form.formSubmissions[form.formSubmissions.length - 1].actionBy === 'Local Unit' &&
        form.formReturns[form.formReturns.length - 1].actionBy === 'County') {
        disabled = true;
      }

      color = 'danger';
      text = 'Amend';
      callback = () => toggleCommentModal(true);
    } else {
      color = 'primary';
      text = 'Submit';
      callback = () => setSubmitModalIsOpen(true);
    }

    return <Button disabled={disabled}
                   onClick={callback}
                   color={color}>
      {text}
    </Button>;
  }, [
    form,
    loading
  ]);

  const lastSubmittedByCounty = useMemo(() => {
    return form.hasSubmissions &&
      form.formSubmissions[form.formSubmissions.length - 1].actionBy === 'County';
  }, [form.hasSubmissions, form.formSubmissions]);

  const renderCountySubmitButton = useMemo(() => (formikProps: FormikProps<any>) => {
    if ((form.isOverdue || form.formReturns.filter((formReturn: FormReturn) => formReturn.actionBy === 'State').length > 0) && lastSubmittedByCounty) {
      return <Button className="mr-1"
                     disabled={loading || form.locked}
                     onClick={() => toggleCommentModal(true)}
                     color="danger">
        Amend
      </Button>;
    } else if (lastSubmittedByCounty) {
      return <Button className="mr-1"
                     disabled={loading || form.locked}
                     onClick={() => setResubmitModalIsOpen(true)}
                     color="primary">
        Resubmit
      </Button>;
    } else {
      return <Button className="mr-1"
                     disabled={loading || form.locked}
                     onClick={() => toggleSubmitModal(false, false, formikProps)}
                     color="primary ">
        Submit
      </Button>;
    }
  }, [
    loading,
    form,
    toggleSubmitModal,
    lastSubmittedByCounty
  ]);

  const showStateFormButtons = useMemo(() => {
    return !isStateViewLocalUnit4022 &&
      isStatePortal &&
      form.stateStatus !== 'IN_PROGRESS' &&
      form.formType === 'FORM_4022_AV';
  }, [isStatePortal, isStateViewLocalUnit4022, form.stateStatus, form.formType]);
  const showReadOnlyView = useMemo(() => {
    return isStatePortal || form.locked;
  }, [isStatePortal, form.locked]);
  const showReturnToLocalUnitButton = useMemo(() => {
    return isCountyUser && form.formType === 'FORM_4022_AV';
  }, [isCountyUser, form.formType]);
  // Remove 'Ad Valorem' or 'Special Acts' from the form name used in the card header as it
  // is also in the form's description
  const scrubbedFormName = useMemo(() => form.name
    ?.replace('Ad Valorem', '')
    ?.replace('Special Acts', ''), [form.name]);
  const shouldShowSaveButton = useMemo(() => {
    return (isLocalUnitUser || isCountyUser) && !isStatePortal;
  }, [isLocalUnitUser, isCountyUser, isStatePortal]);
  const shouldShowCountySubmitButton = useMemo(() => {
    return isCountyUser && hasSubmitAccess && !isStatePortal;
  }, [isCountyUser, hasSubmitAccess, isStatePortal]);
  const shouldShowLocalUnitSubmitButton = useMemo(() => {
    return isLocalUnitUser && hasLocalUnitSubmitAccess && !isStatePortal;
  }, [isLocalUnitUser, isStatePortal, hasLocalUnitSubmitAccess]);
  const isReturnToLocalUnitDisabled = useMemo(() => {
    const isAv = form.formType === 'FORM_4022_AV';
    const hasLocalUnitSubmissions = form.formSubmissions.filter(fs => fs.actionBy === 'LocalUnit').length > 0;
    return !isAv || loading || form.locked || !hasLocalUnitSubmissions;
  }, [loading, form.locked, form.formType, form.formSubmissions]);

  return <div className="Form4022">
    <Formik initialValues={initialValues}
            validationSchema={form4022Schema}
            enableReinitialize={true}
            onSubmit={async () => null}
            validateOnMount={true}>
      {(formikProps) => {
        const totals = calculateTotals(formikProps);
        return <>
          <Card className="mb-3">
            <FormHeader form={form}/>
            <CardBody>
              <Form4022Table form={form}
                             showReadOnlyView={showReadOnlyView}
                             setHasUnsavedChanges={setHasUnsavedChanges}
                             totals={totals.realProperty}
                             classificationsType="real"
                             calculateBoardOfReview2={calculateBoardOfReview2}
                             formikProps={formikProps}/>
              <Form4022Table form={form}
                             showReadOnlyView={showReadOnlyView}
                             setHasUnsavedChanges={setHasUnsavedChanges}
                             totals={totals.personalProperty}
                             calculateBoardOfReview2={calculateBoardOfReview2}
                             classificationsType="personal"
                             formikProps={formikProps}/>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th className="align-middle text-primary w-20"/>
                    <th className="text-center align-middle text-primary w-15">
                      Parcel Count
                    </th>
                    <th className="text-center align-middle text-primary w-15">
                      {form.year - 1} Board of Review
                    </th>
                    <th className="text-center align-middle text-primary w-10">
                      Loss
                    </th>
                    <th className="text-center align-middle text-primary w-15">
                      + or ( - ) Adjustment
                    </th>
                    <th className="text-center align-middle text-primary w-10">
                      New
                    </th>
                    <th className="text-center align-middle text-primary w-15">
                      {form.year} Board of Review
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center align-middle text-primary font-weight-bold text-nowrap w-20">
                      Total Real and Personal
                    </td>
                    <td className="text-center align-middle font-weight-bold">
                      {formatInteger(totals.all.propertyCount)}
                    </td>
                    <td className="text-center align-middle font-weight-bold">
                      {formatInteger(totals.all.boardOfReview1)}
                    </td>
                    <td className="text-center align-middle font-weight-bold">
                      {formatInteger(totals.all.loss)}
                    </td>
                    <td className="text-center align-middle font-weight-bold">
                      {format4022AdjustmentValueWithCommas(totals.all.adjustment)}
                    </td>
                    <td className="text-center align-middle font-weight-bold">
                      {formatInteger(totals.all.newField)}
                    </td>
                    <td className="text-center align-middle font-weight-bold">
                      {formatInteger(totals.all.boardOfReview2)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <FormHistoryTable items={form.formHistory}/>

          {shouldShowSaveButton && <Row>
            <Col className="d-flex justify-content-end">
              <Button className="mr-1"
                      disabled={loading || form.locked}
                      onClick={() => handleSave(formikProps)}
                      color="success">
                Save
              </Button>
              {showReturnToLocalUnitButton && <Button className="mr-1"
                                                      disabled={isReturnToLocalUnitDisabled}
                                                      onClick={() => toggleReturnLocalUnitModalIsOpen(true)}
                                                      color="primary">
                Return to Local Unit
              </Button>}
              {shouldShowCountySubmitButton && renderCountySubmitButton(formikProps)}
              {shouldShowLocalUnitSubmitButton && renderLocalUnitSubmitButton(formikProps)}
            </Col>
          </Row>}

          {showStateFormButtons && <StateFormButtons loading={loading}
                                                     onReturnClick={() => toggleCommentModal(true)}
                                                     onAcceptClick={() => toggleAcceptModal()}/>}

          {!isLocalUnitPortal && <LocalUnitFormNavigator localUnitForm={form}
                                                         isStateUser={isStatePortal}/>}

          {!isStatePortal && <>
            <ConfirmationModal isOpen={submitModalIsOpen}
                               size="lg"
                               title="Submit Form"
                               confirmButtonText="Yes"
                               cancelButtonText="No"
                               confirmCallback={() => toggleSubmitModal(true, isLocalUnitUser, formikProps)}
                               cancelCallback={() => toggleSubmitModal(false, isLocalUnitUser)}>
              <p>
                Are you sure you want to submit the Form {scrubbedFormName} {form.description} for <span className="text-danger">{form.localUnitDisplayName}</span>?
              </p>
            </ConfirmationModal>
            <ConfirmationModal isOpen={resubmitModalIsOpen}
                               size="lg"
                               title="Resubmit Form"
                               confirmButtonText="Yes"
                               cancelButtonText="No"
                               confirmCallback={() => toggleResubmitModal(true, formikProps)}
                               cancelCallback={() => toggleResubmitModal()}>
              <p>
                Are you sure you want to resubmit the Form {scrubbedFormName} {form.description} for <span className="text-danger">{form.localUnitDisplayName}</span>?
              </p>
            </ConfirmationModal>

            <CommentModal onSubmit={(amendmentRequest: object) => handleAmend(formikProps, amendmentRequest as AmendmentRequest)}
                          isOpen={commentModalIsOpen}
                          modalTitle="Reason for Amend"
                          onToggle={() => toggleCommentModal(false)}
                          confirmButtonText="Submit"
                          cancelButtonText="Cancel">
              Provide a reason for amending the Form {scrubbedFormName} {form.description} for <span className="text-danger">{form.localUnitDisplayName}</span>.
            </CommentModal>
          </>}

          {isCountyUser && <CommentModal onSubmit={onReturnToLocalUnit}
                                         isOpen={returnLocalUnitModalIsOpen}
                                         modalTitle="Reason for Return"
                                         onToggle={() => toggleReturnLocalUnitModalIsOpen(false)}
                                         confirmButtonText="Return"
                                         cancelButtonText="Cancel">
            Provide a reason for returning the Form {scrubbedFormName} {form.description} for <span className="text-danger">{form.localUnitDisplayName}</span>.
          </CommentModal>}

          {isStatePortal && <>
            <CommentModal onSubmit={onReturn}
                          isOpen={commentModalIsOpen}
                          modalTitle="Reason for Return"
                          onToggle={() => toggleCommentModal(false)}
                          confirmButtonText="Return"
                          cancelButtonText="Cancel">
              Provide a reason for returning the Form {scrubbedFormName} {form.description} for <span className="text-danger">{form.localUnitDisplayName}</span>.
            </CommentModal>
            <ConfirmationModal isOpen={acceptModalIsOpen}
                               title="Accept Form"
                               confirmButtonText="Yes"
                               cancelButtonText="No"
                               confirmCallback={() => toggleAcceptModal(true)}
                               cancelCallback={() => toggleAcceptModal()}
                               size="lg">
              <p>
                Are you sure you want to accept the Form {scrubbedFormName} {form.description} for <span className="text-danger">{form.localUnitDisplayName}</span>?
              </p>
            </ConfirmationModal>
          </>}
        </>;
      }}
    </Formik>
  </div>;
};

export default Form4022;